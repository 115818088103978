<template>
  <v-container class="ml-5 mr-5 my-5">
    <v-layout row wrap>
      <v-form ref="registerTutorForm" autocomplete='off' v-model="isFormVisiblyValid" lazy-validation>
        <v-stepper v-model="stepperStep" vertical flat>

          <!-- nickname and sex -->
          <v-stepper-step :complete="stepperStep > 1" editable step="1">
            请填写教师基本信息
            <small>将被用来称呼您(并标志您的身份)</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card class="mb-5" flat>
              <v-text-field
                label="称呼"
                name="nickname"
                v-model="nickname"
                :rules="nicknameRules"
              ></v-text-field>
              <v-radio-group
                v-model="sex"
                label="性别"
                name="sex"
                :rules="sexRules">
                <v-radio
                  :key="1"
                  :label='`女`'
                  :value='`F`'
                ></v-radio>
                <v-radio
                  :key="2"
                  :label='`男`'
                  :value='`M`'
                ></v-radio>
              </v-radio-group>
            </v-card>
            <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || '须阅读并同意才能继续']"
              label="我已阅读并同意协议"
              required
            ></v-checkbox>
            <v-btn color="primary"
              @click="submit({ nickname: nickname, sex: sex, userId: userId })"
              :disabled="!isFormVisiblyValid">
              <v-progress-circular
                v-if="showWait"
                indeterminate
                color="white"
                :size="20"
                :width="2"
              ></v-progress-circular>
              <span v-if="showWait">&nbsp;</span>
              注册
            </v-btn>
            <v-btn text @click="cancel">取消</v-btn>
          </v-stepper-content>
          <v-stepper-step :complete="stepperStep > 2" step="2">
            完成
          </v-stepper-step>

          <v-stepper-content step="2">
            {{ error }}
            <v-btn color="primary"
              v-if="!error"
              @click="$router.push('login')">
              前往登录
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  components: {
  },
  data () {
    return {
      nickname: '',
      nicknameRules: [
        v => !!v || '请填写称呼'
      ],
      sex: '',
      sexRules: [
        v => !!v || '请选择性别'
      ],
      checkbox: false,
      error: null,
      stepperStep: 1,
      userId: null,
      showWait: false,
      isFormVisiblyValid: true
    }
  },
  mounted () {
    this.userId = this.$store.state.route.params.userId
  },
  methods: {
    async submit (tutorInfo) {
      if (this.$refs.registerTutorForm.validate() && this.userId) { // the form element is marked as ref="form" and can be refered here
        this.showWait = true
        try {
          const response = await this.registerTutor(tutorInfo)
          this.showWait = false
          if (response.tutorId) {
            this.error = null
            console.log('tutor has been created! response.data:', response)
          } else {
            console.log('something went wrong in creating tutor')
          }
        } catch (error) {
          this.error = error.response.data.msg
          this.showWait = false
        }
        this.stepperStep = 2
      }
    },
    async registerTutor (tutorInfo) {
      try {
        const response = await AuthenticationService.registerTutor({
          nickname: tutorInfo.nickname,
          sex: tutorInfo.sex,
          userId: tutorInfo.userId
        })
        return response.data
      } catch (err) {
        return err.response.data
      }
    },
    cancel () {
      this.$refs.registerUserForm.reset()
      this.error = null
      this.stepperStep = 1
      this.userId = null
      this.showWait = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
